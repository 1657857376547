import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../../assets/styles/layout"

import { FooterContainer } from "../../components/global/common/containers"

import Partners from "../../components/global/Partners"
import CallToAction from "../../components/global/CallToAction"
import Footer from "../../components/global/Footer"

import { CaseStudiesHeader } from "../../components/case-studies/CaseStudiesHeader"
import { CaseStudies } from "../../components/case-studies/Main"
import Testimonials from "../../components/global/Testimonials"

export const query = graphql`
  query ($id: String!, $language: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfCallToAction {
        ctaPersonPosition
        ctaPersonName
        ctaForm
        ctaImage {
          altText
          atttachement_pl {
            altTextPl
          }
          sourceUrl
          srcSet
        }
        ctaContactOptions {
          eMail
          phone
        }
        ctaButtons {
          ctaButton1 {
            url
            title
            target
          }
          ctaButton2 {
            target
            url
            title
          }
        }
        ctaIntro
        ctaTitle
      }
      acfCaseStudiesPage {
        caseStudiesBannerTitle {
          textGray
          textGreen
        }
        testimonialsTitle
        testimonials {
          ... on WpTestimonial {
            id
            acfSingleTestimonials {
              content
              testimonialname
              position
              link {
                target
                title
                url
              }
              logo {
                altText
                atttachement_pl {
                  altTextPl
                }
                srcSet
                sourceUrl
              }
              testimonialimage {
                altText
                atttachement_pl {
                  altTextPl
                }
                srcSet
                sourceUrl
              }
            }
          }
        }
      }
    }
    allWpCaseStudy(
      sort: { fields: menuOrder, order: ASC }
      filter: { language: { slug: { eq: $language } } }
    ) {
      nodes {
        id
        menuOrder
        featuredImage {
          node {
            altText
            atttachement_pl {
              altTextPl
            }
            sourceUrl
            srcSet
          }
        }
        acfCaseStudy {
          intro
          whiteLogo {
            altText
            atttachement_pl {
              altTextPl
            }
            sourceUrl
            srcSet
          }
        }
        caseStudyCategories {
          nodes {
            slug
          }
        }
        title
        uri
      }
    }
    allWpCaseStudyCategory(filter: { language: { slug: { eq: $language } } }) {
      nodes {
        slug
        name
        id
      }
    }
  }
`

const CaseStudiesPage = ({
  pageContext, data: { wpPage, allWpCaseStudyCategory, allWpCaseStudy },
}) => {
  const { seo } = wpPage

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPage} />
      )}
      <CaseStudiesHeader
        title={wpPage.acfCaseStudiesPage.caseStudiesBannerTitle}
        pageContext={pageContext}
      />
      <CaseStudies
        categories={allWpCaseStudyCategory.nodes}
        allCaseStudies={allWpCaseStudy.nodes}
      />
      <Testimonials
        data={wpPage.acfCaseStudiesPage}
        containerStyle={{ backgroundColor: "var(--light-gray)" }}
      />
      <Partners />
      <FooterContainer>
        <CallToAction data={wpPage.acfCallToAction} />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default CaseStudiesPage
