import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { LineWrapper } from "../global/common/containers"

import { CaseStudiesCategories } from "./Categories"
import { CaseStudiesList } from "./List"

const Container = styled(LineWrapper)`
  padding-top: 115px;

  @media ${minD.tabletL} {
    padding-bottom: 20px;
  }

  @media ${maxD.tabletL} {
    padding-top: 60px;
  }
`

const CaseStudies = ({ categories, allCaseStudies }) => {
  const [currentCategory, setCategory] = useState("all")
  const [currentCaseStudies, setCurrentCaseStudies] = useState(allCaseStudies)
  const [caseStudiesVisibility, setCaseStudiesVisibility] = useState(true)

  const firstRender = useRef(true)

  const animationDuration = 220
  const caseStudiesFilteringDelay = animationDuration + 50

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const categoryFromUrl = params.get("category") || "all"
    setCategory(categoryFromUrl)
  }, [])

  useEffect(() => {
    if (!firstRender.current) {
      const params = new URLSearchParams(window.location.search)

      if (currentCategory === "all") {
        params.delete("category")
      } else {
        params.set("category", currentCategory)
      }
      
      const newUrl = `${window.location.pathname}?${params.toString()}`
      window.history.pushState({}, "", newUrl)
    }
  }, [currentCategory])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      setCaseStudiesVisibility(false)
    }

    setTimeout(() => {
      if (currentCategory === "all") {
        setCurrentCaseStudies(allCaseStudies)
      } else {
        const filteredCaseStudies = allCaseStudies.filter(caseStudy => {
          const caseStudyCategories = caseStudy.caseStudyCategories.nodes

          return caseStudyCategories.some(
            category => category.slug === currentCategory
          )
        })

        setCurrentCaseStudies(filteredCaseStudies)
      }

      setCaseStudiesVisibility(true)
    }, caseStudiesFilteringDelay)
  }, [currentCategory, allCaseStudies, caseStudiesFilteringDelay])

  return (
    <main>
      <Container>
        <CaseStudiesCategories
          categories={[
            {
              id: "all-case-studies",
              slug: "all",
              name: "All projects",
              language: { locale: "en_GB" },
            },
          ].concat(categories)}
          setCategory={setCategory}
        />
        <CaseStudiesList
          caseStudies={currentCaseStudies}
          isVisible={caseStudiesVisibility}
          {...{ animationDuration }}
        />
      </Container>
    </main>
  )
}

export { CaseStudies }
